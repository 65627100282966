import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/aplikacja-do-obslugi-zlecen.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    
    zdjecie1: file(relativePath: { eq: "baza-urzadzen2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 85, layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "kalendarz-mobilny.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "mapa-mobilny.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie4: file(relativePath: { eq: "lista-zlecen-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie5: file(relativePath: { eq: "karta-zlecenia-app.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie6: file(relativePath: { eq: "karta-zlecenia-app1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie7: file(relativePath: { eq: "mapa-dojazdu.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie8: file(relativePath: { eq: "karta-zlecenia-app2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie9: file(relativePath: { eq: "karta-zlecenia-app3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie10: file(relativePath: { eq: "karta-zlecenia-app4.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const raportpracy = ({ data }) => {
  return (
    <Artykul
      title="Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta"
      keywords={["aplikacja do obsługi zleceń"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta"
      metaTitle="Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta"
      metaDescription="Aplikacja do obsługi zleceń 📱 Powiadomienia ᛫ Informacje o zleceniu ᛫ Lokalizacje zleceń i pracowników ᛫ Czas pracy ᛫ Automatyczne protokoły"
    >
        <Container className="container container--text">
        <p>
        Realizacja zleceń w terenie to główny obszar Twojej działalności? Jesteś osobą 
        odpowiedzialną za zarządzanie przepływem pracy i monitorowanie realizacji obowiązków? 
        Poszukujesz wsparcia dla swoich serwisantów w postaci oprogramowania informatycznego? 
        Chcesz gromadzić wszystkie niezbędne informacje w&nbsp;jednym, dostępnym dla wszystkich 
        pracowników miejscu? A może potrzebujesz narzędzia, który zautomatyzuje realizację 
        Twoich własnych obowiązków? Mamy dla Ciebie kompleksowe rozwiązanie – program dla 
        przedsiębiorstwa serwisowego z&nbsp;aplikacją do obsługi zleceń.
        </p>
        <p>
        Przeczytaj nasz artykuł lub sprawdź poniższe wideo i&nbsp;dowiedz się:
        </p>
        <p style={{
          paddingBottom: 20,
        }}>
        <ul>
        <li>jak program do serwisu pomoże Ci w&nbsp;codziennej pracy,</li>
        <li>jak wykorzystać aplikację mobilną do pracy terenie,</li>
        <li>jakie Twoje działania mogą zostać usprawnione przez system do obsługi zgłoszeń.</li>
        </ul>
        </p>

        <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="qHrBm6zsZ24"
          title="Aplikacja do obsługi zleceń – podstawowe narzędzie każdego serwisanta"
        />
      </div>

        </Container>
    <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Specyfika pracy terenowego serwisanta</strong>
      </h2>
      <p>
      Charakterystyka pracy serwisanta terenowego, czyli pracownika wykonującego swoje 
      obowiązki poza siedzibą biura różni się znacząco od specyfiki działań podejmowanych 
      przez stacjonarnych pracowników. Konieczne jest posiadanie <strong>większej liczby 
      dokładnych informacji i&nbsp;wskazówek</strong>, a także <strong>stały, nieograniczony dostęp 
      do nich</strong>.
      </p>
      <p> 
      Co więcej, taki rodzaj organizacji pracy wpływa także na stosunki, które zachodzą między 
      podwładnymi, a&nbsp;przełożonymi. Większą uwagę przykłada się do <strong>kontroli 
      i&nbsp;monitorowania efektywności podejmowanych działań.</strong>
      </p>
      <p>
      Każdy mobilny serwisant powinien wraz z&nbsp;nowym zleceniem do realizacji otrzymać garść 
      niezbędnych informacji. Tradycyjna, papierowa dokumentacja może być kłopotliwa, 
      a bezpośredni, indywidualny kontakt kierownika z pracownikiem to strata cennego czasu 
      i ryzyko zapomnienie o wielu istotnych kwestiach.
      </p>
      <p>
      Z pomocą przychodzi <strong>funkcjonalny program do serwisu wyposażony w&nbsp;aplikację do 
      obsługi zleceń</strong>. To swoista baza wiedzy dla pracownika, który korzystając 
      z jej możliwości może dowiedzieć się o:
      </p>
      <p>
      <ul>
      <li>miejscu i&nbsp;terminie realizacji zadania,</li>
      <li>występującym problemie i&nbsp;działaniach, które mają go rozwiązać,</li>
      <li>sprzęcie, którego dotyczy zlecenie, jego specyfikacji i&nbsp;podjętych wcześniej krokach.</li>
      </ul>
      </p>
      <p>
      To także <strong>wsparcie przepływu informacji w&nbsp;przedsiębiorstwie serwisowym</strong> oraz 
      pomoc dla kierowników mierzących się z&nbsp;trudnościami procesu zarządzania zespołem 
      i kontrolowania jego wyników.
      </p>

      </Container>

       <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zwiększyć efektywność pracy serwisantów?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;skorzystać z&nbsp;aplikacji mobilnej dla serwisantów 
            </Link>
          </div>
          </div>
    </section>


      <Container className="container container--text">

       <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Aplikacja do obsługi zleceń, a&nbsp;wersja przeglądarkowa – kiedy mają znaczenie?</strong>
      </h2>

      <p>
      <strong>Aplikacja do obsługi zleceń to jedna z&nbsp;funkcjonalności kompleksowego rozwiązania 
      do zarządzania pracą przedsiębiorstwa serwisowego</strong>. Jej znaczenie jest największe 
      dla serwisantów, którzy z jej możliwości <strong>mogą korzystać na każdym etapie realizacji 
      swoich codziennych obowiązków</strong>. Rola przeglądarkowej wersji systemu jest 
      zdecydowanie większa dla samych kierowników i koordynatorów, ponieważ pozwala 
      im <strong>sprawnie planować podział pracy, a także podsumowywać podejmowane działania.</strong>
      </p> 

      <p>
      Uporządkujmy jednak fakty i przejdźmy przez przykładowe zlecenie serwisowe krok po 
      kroku. Dzięki temu poznamy zalety opisywanego rozwiązania i przekonamy się, w których 
      momentach ma ono zastosowanie.
      </p>

      </Container>
      <Container className="container container--text">

      <h3 style={{textAlign: "left"}}><strong>Jak postąpić z&nbsp;nowym zleceniem?</strong></h3>

     <p>
     Wyobraź sobie, że Twój kontrahent zadzwonił ze zgłoszeniem dotyczącym awarii lub 
     instalacji sprzętu. Czy otrzymane od niego informacje możesz od razu przekazać swojemu 
     serwisantowi bez żadnego przetworzenia? Jeśli chcesz, aby praca została wykonana 
     we właściwy sposób konieczne jest uszczegółowienie pewnych kwestii i przekazanie 
     pracownikowi odpowiednich wskazówek.
     </p>
     <p>
     W tym momencie po raz pierwszy może zostać wykorzystany w Twoim procesie program do 
     serwisu. Otrzymane zgłoszenie należy przekształcić w zlecenie zawierające wszelkie 
     kluczowe dane, a w celu ich uzupełnienia warto skorzystać z funkcji <strong>baza urządzeń 
     serwisowanych</strong>. To w niej zawarte są wszystkie szczegółowe dane o&nbsp;sprzęcie 
     klienta – specyfikacja, parametry i&nbsp;realizowane wcześniej zlecenia. Znajomość tych 
     elementów pomoże kierownikowi w&nbsp;wyborze odpowiedniego pracownika do realizacji 
     zlecenia – takiego, który specjalizuje się w danym obszarze lub pracował w przeszłości 
     z określonym urządzeniem.
     </p>
     <p>
     Odpowiednie opisanie zlecenia jest kluczowe. <strong>To, co jako kierownik zamieścisz 
     w przeglądarkowej wersji systemu, Twój serwisant zobaczy w&nbsp;swojej aplikacji do obsługi 
     zleceń</strong> i&nbsp;zgodnie z tym będzie działał.
     </p>

     <Lightbox
        style={{
          paddingTop: 20,
        paddingBottom: 10,
        }}
        images={[data.zdjecie1]}
        alts={["Baza urządzeń serwisowanych"]}
      />

      </Container>

      <Container className="container container--text">

      <p>
      Wiedza to jedno, a dostępność to drugie. Jeśli chcesz sprawniej dobierać pracowników 
      do realizacji zleceń i&nbsp;unikać problemów związanych z niedostępnością czasową, możesz 
      skorzystać z zalet dwóch kolejnych funkcji – <strong>kalendarza</strong> i&nbsp;<strong>harmonogramu</strong>. Wystarczy, 
      że spojrzysz na indywidualny rozkład pracy danego serwisanta i&nbsp;dzięki temu sprawdzisz, 
      czy w&nbsp;określonym terminie posiada on lukę czasową o odpowiedniej rozpiętości.
      </p>

       <Lightbox
        style={{
          paddingTop: 20,
        paddingBottom: 10,
        }}
        images={[data.zdjecie2]}
        alts={["Kalendarz serwisanta"]}
      />

      </Container>

      <Container className="container container--text">

      <p>
      Idealnym dopełnieniem procesu rozdzielania zleceń jest <strong>możliwość śledzenia 
      lokalizacji pracowników</strong> (udostępnianej przez aplikację do obsługi 
        zleceń) i <strong>miejsc docelowych</strong> (zaciąganych z adresu kontrahenta). 
      To jeszcze większe prawdopodobieństwo wybrania najlepszego serwisanta, a przede 
      wszystkim klucz do oszczędności kosztów dojazdu oraz czasu.
      </p>

       <Lightbox
        style={{
          paddingTop: 20,
        paddingBottom: 10,
        }}
        images={[data.zdjecie3]}
        alts={["Lokalizacja dzięki aplikacji do obsługi zleceń"]}
      />

      </Container>

      <Container className="container container--text">

      <h3 style={{textAlign: "left"}}><strong>Co zyskasz dzięki aplikacji do obsługi zleceń</strong></h3>

      <p>
      Serwisant mobilny może zostać poinformowany o nowym zleceniu poprzez <strong>wbudowany komunikator 
      systemowy</strong>. Jest to dość prosta opcja, ale niekoniecznie najbardziej efektywna. 
      W sprawniejszym działaniu pomóc może <strong>automatyzacja</strong>, czyli zastąpienie 
      ludzkiej pracy technologią. Wystarczy zdefiniowanie odpowiednich reguł, a powiadomienie 
      o każdym nowym zadaniu pracownik będzie otrzymywał w&nbsp;preferowany sposób – mailowo, 
      SMS-owo lub poprzez aplikację do obsługi zleceń.
      </p>

      <p>
      Serwisant w aplikacji mobilnej może odbierać powiadomienia, ale to tylko pierwszy krok 
      stanowiący początek współpracy z aplikacją do obsługi zleceń.
      </p>

      </Container>

      <Container className="container container--text"> 
      <div className="text-answers">
            <div className="text-block">

      <p>
      Aby uprościć działania zabieganego pracownika terenowego, aplikacja jest prosta w obsłudze 
      i&nbsp;skupia się na dwóch głównych widokach – <strong>liście zleceń</strong> oraz <strong>karcie zlecenia.</strong>
      </p> 

      <p>Lista zleceń to podręczny harmonogram pracy każdego serwisanta, który przedstawia 
      zlecenia konieczne do wykonania w danym dniu – <strong>“Na dziś”</strong> oraz prace 
      zaplanowane na najbliższe dni – <strong>“Nadchodzące”</strong>. W tym miejscu pracownik 
      może sprawdzić, w jakim terminie powinien pojawić się u&nbsp;danego kontrahenta i gdzie 
      znajduje się siedziba, do której powinien się udać. To pierwszy krok do efektywnego 
      wykonania zlecenia.
      </p>

       </div>
<div className="text-block">
<center>
      <Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "300px"
        }}
        images={[data.zdjecie4]}
        alts={["Lista zleceń w aplikacji do obsługi zleceń"]}
      />
      </center>

</div>
</div>
     
      </Container>

      <Container className="container container--text">
    
      <p>
      Widok poświęcony każdemu pojedynczemu zleceniu jest natomiast <strong>szczegółowym 
      przedstawieniem prac koniecznych do wykonania</strong>. Oprócz wspomnianych wyżej 
      informacji, na karcie zlecenia znajdują się też:
      </p>
      <p>
      <ul>
      <li>dane o&nbsp;sprzęcie,</li>
      <li>opis zlecenia,</li>
      <li>działania do wykonania,</li>
      <li>opcje pozwalające podsumować pracę,</li>
      <li>dokumentacja zdjęciowa,</li>
      <li>miejsce na podpis klienta.</li>
      </ul>
      </p>
      <p>
      Pola te są łatwo edytowalne z poziomu aplikacji do obsługi zleceń oraz wersji 
      przeglądarkowej, do której można w szybki sposób trafić bezpośrednio z aplikacji. Co 
      więcej, pola te charakteryzują się <strong>dużą elastycznością i zgodnie z własnymi 
      preferencjami mogą zostać zmienione.</strong>
      </p>

      <center><Lightbox
style={{
        paddingTop: 10,
        paddingBottom: 10,
      maxWidth: "600px"}}

                images={[data.zdjecie5, data.zdjecie6 ]}
                alts={["Karta zlecenia aplikacji dla serwisanta mobilnego", "Karta zlecenia aplikacji dla serwisanta mobilnego"]}
              />
              </center>

      </Container>
      
      <Container className="container container--text">
      <h3 style={{textAlign: "left"}}><strong>Jak wykorzystać aplikację do obsługi zleceń w&nbsp;praktyce?</strong></h3>
       </Container>

      <Container className="container container--text"> 
      <div className="text-answers">
            <div className="text-block">
      <p>
      Aplikacja do obsługi zleceń może być najlepszym przyjacielem każdego serwisanta. Nie 
      zostawia go w potrzebie tylko wskazując miejsce, do którego powinien się on udać. Robi 
      coś znacznie więcej – prezentuje <strong>mapę dojazdu do kontrahenta</strong>. Dzięki tej 
      opcji pracownik widzi na swoim smartfonie lokalizację swoją oraz destynację docelową, wie 
      jaką trasą powinien się kierować, a także to ile czasu powinna mu ona zająć.
      </p>
      <p>
      Przedstawione wyżej elementy na Karcie zlecenia odgrywają szczególną rolę, w&nbsp;momencie, 
      w którym serwisant znajduje się już u kontrahenta. Rozpoczyna wtedy realizację zlecenia, 
      informując o&nbsp;tym swojego szefa poprzez <strong>zmianę statusu – fazy zlecenia</strong>.
      </p>
      </div>
      <div className="text-block">

       <center><Lightbox
        style={{
          paddingTop: 10,
        paddingBottom: 10,
        maxWidth: "300px"
        }}
        images={[data.zdjecie7]}
        alts={["Mapa w aplikacji do obsługi zleceń"]}
      />
      </center>
      </div>
      </div>

      </Container>
      <Container className="container container--text">

      <p>
      Postępując zgodnie z wytycznymi i&nbsp;specyfikacją sprzętu, z którą miał możliwość się 
      zapoznać, pracownik terenowy może odhaczać w aplikacji do obsługi zleceń kolejno wykonywane 
      kroki na <strong>czekliście</strong>, a&nbsp;wszystko podsumować w polu dedykowanym <strong>opisowi 
      podjętych działań</strong> i&nbsp;uzupełnić to <strong>dokumentacją zdjęciową</strong>. Dodatkowo, 
      może on określić <strong>wykorzystany czas</strong>, a całość zwieńczyć <strong>elektronicznym 
      podpisem klienta</strong> potwierdzającym jakość wykonanej pracy.
      </p>
      <p> 
      Zamieszczone na Karcie zlecenia w aplikacji do obsługi zleceń dane powinny w&nbsp;wyczerpujący 
      sposób przedstawiać zakres zrealizowanych prac. Dlaczego? Będą one podstawą do 
      automatycznego wygenerowania protokołu, który trafi w ręce klienta.
      </p>

     <center><Lightbox
style={{
        paddingTop: 10,
        paddingBottom: 10,
      maxWidth: "800px"}}

                images={[data.zdjecie8, data.zdjecie9, data.zdjecie10]}
                alts={["Karta zlecenia aplikacji dla serwisanta mobilnego", "Karta zlecenia aplikacji dla serwisanta mobilnego", "Karta zlecenia aplikacji dla serwisanta mobilnego"]}
              /></center>

   
      </Container>
      
  
      <Container className="container container--text">
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      ><strong>Podsumowanie</strong>
      </h2>
      <p>
   Wyzwania i problemy, które niesie ze sobą praca serwisantów terenowych to aspekt, 
   z&nbsp;którym mierzy się wiele przedsiębiorstw. Ty nie musisz być w tym gronie. Wystarczy, 
   że zdecydujesz się na wdrożenie rozwiązania informatycznego, jakim jest program serwisowy 
   działający wraz z aplikacją do obsługi zgłoszeń. To szansa dla Ciebie na pokonanie 
   konkurencji i wzniesienie swojego biznesu na wyższy poziom.
     </p>
     <p>
     Mamy nadzieję, że dostrzegasz możliwość zastosowania omawianego oprogramowania w swojej 
     firmie. Jeśli posiadasz jakiekolwiek pytania lub masz 
     wątpliwości – <Link to="/kontakt/"><strong>skontaktuj się z nami.</strong></Link> Z chęcią Ci doradzimy. 
      </p>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zwiększyć efektywność pracy serwisantów?
</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby uzyskać dostęp do bezpłatnej 30-dniowej wersji systemu


</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;skorzystać z&nbsp;aplikacji mobilnej dla serwisantów 
            </Link>
          </div>
          </div>
    </section>
      <p>
        <strong>Polecamy również:</strong>
        </p>
        <p>
        <ul>
          <li>
            <Link to="/analiza-przedwdrozeniowa/">Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?</Link>
          </li>
          <li>
            <Link to="/raport-pracy/">
              {" "}
              Automatyczny raport pracy serwisowej. Zarządzaj serwisem w jednym narzędziu.
            </Link>
          </li>
          <li>
            <Link to="/modul-serwisowy/">
              {" "}
             Dlaczego warto łączyć moduł serwisowy z&nbsp;systemem CRM?
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default raportpracy;
